import React from "react";
import ReactDOM, {render} from "react-dom";
import PropTypes from "prop-types";
import Immutable, {Map, List} from "immutable";
import TableHeaders from "./table-headers";
import Directory from "./directory";

class DirectoryData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            headers: [],
            sortedData: []
        };
    }

    render() {
        const { data, filterStr } = this.props;
        filterStr.toLowerCase();

        let filteredData = data.slice();
        let cells = filteredData.filter( row => {
            return row.row_name !== undefined && row.row_name.toLowerCase().includes( filterStr )
        });

        return (
            <tbody className="list">
            {cells.map( (row, i) => (
                <Directory key={i} {...row} />
            ))}
            </tbody>
        )
    }
}

DirectoryData.propTypes = {
    filterStr: PropTypes.string,
    data: PropTypes.array
};

DirectoryData.defaultPropTypes = {
    filterStr: "",
    data: []
};

export default DirectoryData;