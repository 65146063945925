/*global embedFormSite, EmbedManager */
let embedManager = typeof EmbedManager === 'undefined' ? {} : EmbedManager;

const runEmbed = () => {
    try {
        embedManager.embed({
            key: embedFormSite.embedKey,
            width: '100%',
            mobileResponsive: true
        });
    } catch ( exception ) {
        console.warn( exception.message );
    }

};

embedManager.hasOwnProperty( 'embed' ) ? runEmbed() : false;
