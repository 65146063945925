/*global requestButton */
const isHome = document.body.classList.contains('home');

if ( isHome ) {
    const sliderElem = document.querySelector('.main-carousel');
    const buttonClasses = ['o-button', 'o-button--cta', 'o-button--request'];
    const buttonText = 'Request More Information';
    const buttonHref = '/request';

    function createButton() {
        let button = document.createElement( 'a' );
        button.setAttribute( 'href', buttonHref );
        buttonClasses.forEach( clas => {
            button.classList.add( clas );
        });
        // IE9/10 does not support multiple args for .add()
        //button.classList.add( ...buttonClasses );
        button.innerText = buttonText;
        return button;
    }

    function initSlider( cb ) {

        const slider = new Flickity(sliderElem, {
            cellAlign: 'left',
            setGallerySize: false,
            contain: true,
            prevNextButtons: true,
            pageDots: false,
            wrapAround: true,
            autoPlay: 5000,
            percentPosition: false
        });

        slider.on( 'load', cb() );
    }

    function insertRequestElement() {
        const slideElems = sliderElem.querySelectorAll( '.c-hero-slider__slide' );
        [...slideElems].forEach( elem => {
            let captionElem = elem.querySelector( '.c-hero-slider__slide-caption' );
            let button = createButton();
            elem.appendChild( button );
            //captionElem.appendChild( button );
        });
    }

    initSlider( insertRequestElement );

}