import React from "react";
import ReactDOM, {render} from "react-dom";
import PropTypes from "prop-types";
import Testimonial from "./testimonial";
import RestData from "./RestData";

class TestimonialContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            quote: null,
            name_class: null,
            bg_image: null,
            visibility: 'u-cloaked'
        };
    }

    handleChange(payload) {
        this.setState({
            quote: payload.testimonial_quote,
            name_class: payload.testimonial_name_class,
            bg_image: payload.testimonial_image,
            visibility: 'u-uncloaked'
        });
    }

    componentDidMount() {
        let rest = new RestData();
        rest.getTestimonial( payload => {
            this.handleChange(payload);
        });
    }

    render() {
        return (
            <Testimonial {...this.state} />
        );
    }
}

Testimonial.propTypes = {
    quote: PropTypes.string,
    name_class: PropTypes.string,
    bg_image: PropTypes.string,
    visibility: PropTypes.string
};

if ( document.getElementById( 'testimonialMount' ) ) {
    ReactDOM.render( <TestimonialContainer />, document.getElementById( 'testimonialMount' ) );
}