import Tabby from "Tabby";

const tabsContainer = document.querySelector('.c-tabs');
const tabsDefaultActiveElement = document.querySelector( 'a[href="#tabdegrees"]' );
const ACTIVE_CLASS = 'active';
const HOVER_CLASS = 'tab-hover';
const STORAGE_KEY = 'mwcc-dc-tab';
let currentHash = location.hash;

/**
 * Tabby tabs callback - called on every tab toggle
 * @param tabs
 * @param toggle
 */
const tabbyCb = ( tabs, toggle ) => {
    if ( toggle && toggle.hasAttribute( 'href' ) ) {
        sessionStorage.setItem( STORAGE_KEY, toggle.getAttribute( 'href' ) );
    }
};

const tabbyInit = () => {
    let selectedTab = sessionStorage.getItem( STORAGE_KEY );
    if ( selectedTab ) {
        currentHash = selectedTab;
        let tabElement = document.querySelector( `a[href="${currentHash}"]` );

        if ( tabElement ) {
            tabElement.classList.add( ACTIVE_CLASS );
            tabElement.parentNode.classList.add( ACTIVE_CLASS );
        }
    } else {
        tabsDefaultActiveElement.classList.add( ACTIVE_CLASS );
        tabsDefaultActiveElement.parentNode.classList.add( ACTIVE_CLASS );
    }

    currentHash === '' ? Tabby.toggleTab('#tabdegrees') : Tabby.toggleTab(currentHash);
};

let tabSelector;

if ( tabsContainer ) {
    tabSelector = tabsContainer.querySelectorAll('a[data-tab]');

    Tabby.init({
        selectorToggle: '[data-tab]', // Tab toggle selector
        selectorToggleGroup: '[data-tabs]', // Tab toggle group selector
        selectorContent: '[data-tabs-pane]', // Tab content selector
        selectorContentGroup: '[data-tabs-content]', // Tab content group selector
        toggleActiveClass: 'active', // Class added to active toggle elements
        contentActiveClass: 'active', // Class added to active tab content areas
        initClass: 'js-tabby', // Class added to <html> element when initiated
        stopVideo: true, // [Boolean] If true, stop videos when tab closes
        callback: tabbyCb // Function that's run after tab content is toggled
    });

    [...tabSelector].forEach(link => {
        link.addEventListener('mouseenter', () => {
            link.parentNode.classList.add(HOVER_CLASS);
        });
        link.addEventListener('mouseleave', () => {
            link.parentNode.classList.remove(HOVER_CLASS);
        });
    });

    // check sessionStorage first
    tabbyInit();
    // Toggle first tab / open by default

}

