import React from "react";
import PropTypes from "prop-types";
import Gallery from "react-photo-gallery";
import Lightbox from "react-image-lightbox";

class GalleryGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false
        };

        this.imageOnClick = this.imageOnClick.bind( this );
        this.lightboxOnClose = this.lightboxOnClose.bind( this );
    }

    componentDidMount() {

    }

    /**
     *
     * @param event
     * @param args array( index, next, photo, previous)
     */
    imageOnClick( event, args ) {
        const {index} = args;
        this.setState({
            isOpen: true,
            photoIndex: index
        });
    }

    lightboxOnClose() {
        this.setState({
            isOpen: false,
            photoIndex: 0
        });
    }

    render() {
        const {collection, lightboxColl} = this.props;

        const {photoIndex, isOpen} = this.state;
        return (
            <div className="section">
                <Gallery
                    photos={collection}
                    columns={4}
                    margin={4}
                    onClick={this.imageOnClick}
                />

            {isOpen && <Lightbox mainSrc={lightboxColl[photoIndex].src}
                                 nextSrc={lightboxColl[(photoIndex + 1) % lightboxColl.length].src}
                                 prevSrc={lightboxColl[(photoIndex + lightboxColl.length - 1) % lightboxColl.length].src}
                                 onCloseRequest={this.lightboxOnClose}
                                 onMovePrevRequest={() => this.setState({
                                    photoIndex: (photoIndex + lightboxColl.length - 1) % lightboxColl.length
                                 })}
                                 onMoveNextRequest={() => this.setState({
                                    photoIndex: (photoIndex + 1) % lightboxColl.length
                                })}
                        />
            }

            </div>
        )
    }
}

GalleryGrid.propTypes = {
    collection: PropTypes.array
};

export default GalleryGrid;