import React from "react";
import ReactDOM, {render} from "react-dom";
import DirectoryData from "./directory-data";
import Immutable from "immutable";

class TableHeaders extends React.Component {

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind( this );
    }

    handleClick( e ) {
        /*let immutableHeaders = Immutable.fromJS(this.props.headers.slice());
        let immutableData = Immutable.fromJS(this.props.directoryData.slice());
        this.props.onUpdate( immutableHeaders, immutableData );*/
    }

    render() {
        const { headers, directoryData } = this.props;

        let headings = Immutable.fromJS( headers.slice() );
        let data = Immutable.fromJS( directoryData.slice() );

        return (
            <tr>
                {headings.map( (cell, i) => (
                    <th key={i}
                        onClick={this.handleClick}
                    >
                        {cell.get('label')}
                        {cell.get('notation') !== '' ? ` ${cell.get('notation')}`: ''}
                    </th>
                ))}
            </tr>
        )
    }
}

/*const TableHeader = ({ headers }) => (
    <tr>
        {headers.map( p => {
            return <th className={p.clas}>{p.label}</th>
        })}
    </tr>
);*/

export default TableHeaders;