const FOCUS_CLASS = 'focused';

const searchContainer = document.querySelector('.c-search-form');
const searchInput = searchContainer.querySelector('input[type="search"]');
const searchGlass = searchContainer.querySelector('button[type="submit"]');

try {
    searchInput.addEventListener('focus', () => {

        if (!searchGlass.classList.contains(FOCUS_CLASS)) {
            searchGlass.classList.add(FOCUS_CLASS);
        }
    });

    searchInput.addEventListener('blur', () => {

        if (searchGlass.classList.contains(FOCUS_CLASS)) {
            searchGlass.classList.remove(FOCUS_CLASS);
        }
    });
} catch ( e ) {
    console.warn(`Elements not available to attach listener to - ${e.message}`);
}
