
export default class JSONParse {

    constructor(json) {
        this._json = json;
    }

    parse() {
        this._json = JSON.parse(this._json);
    }

    get() {
        return this._json;
    }
}