/*global settings, embedFormSite */
import "babel-polyfill";
import "./components/googleFontLoader";
import "./navigator";
//import "./components/pagination";
import "./components/flickitySlider";
import "./components/sliderPosition";
import "./menu/navigation";
import "./tabs/tabs";
import "./search/search";
import "./fetch/twitterFeed";
import "./embed/embedManager";
import "./dropdowns/categoryDrop";

import "./r-components/testimonial-container";
import "./r-components/directory-container";
import "./r-components/tabs-container";
import "./r-components/tabs";

import "./r-components/gallery-container";

//import "./r-components/header-container";
//import "./r-components/catalog-container";
//import "./category/data/categories";
//import "./category/select";

//import "./r-components/missingPageContainer";

//import "./redirect/redirect";
//import "./r2-components/index";

import fluidVids from "./video/fluidvids";
// https://github.com/coryhouse/pluralsight-redux-app-used-to-build-script/pull/1
//import "./index"; // Router entry point, goes to App

fluidVids();

const schools = [
    "Yorktown",
    "Washington & Lee",
    "Wakefield"
];



// extracs from array, no mutation
/*let delimited = schools.join(", ");
console.log(delimited);*/

// creates a new array of the schools that begin with the letter “W” we could use the array.filter method
/**
 * Array.filter is a built-in JavaScript function that produces a new array from a source array.
 * This function takes a predicate as its only argument. A predicate is a function that always returns
 * a boolean value: true or false. Array.filter invokes this predicate once for every item in the array.
 * That item is passed to the predicate as an argument and used to decide if that item shall be added to the new array.
 * In this case, array.filter is checking every school to see if it begins with a “W”.
 * @type {Array.<string>}
 */
/*const wSchools = schools.filter( school => school[0] === 'W' );
console.log(wSchools);

// map function returning an object for every school.
const highSchools = schools.map( school => ({ name: `${school} High School` }) );
console.log(highSchools, 'highschools');*/
//console.log(settings, 'settings');

//import "./wp-rest-api/api";
//import "./components/headroom";
//import "./components/api";
