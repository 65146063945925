
let request = args => {

    let xhr = new XMLHttpRequest();
    const { ajax_url, action, tw_auth_nonce } = args;

    let url = `${ajax_url}?action=${action}&tw_ajax_nonce=${tw_auth_nonce}`;

    return new Promise( (resolve, reject) => {

        xhr.open('GET', url);

        xhr.onload = () => {
            if (xhr.status === 200 && xhr.readyState === XMLHttpRequest.DONE) {
                resolve(xhr.responseText);
            } else {
                reject(xhr.statusText);
            }
        };
        xhr.onerror = () => xhr.reject(xhr.responseText);
        //xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.send(null);
    });
};

export default request;
