import React from "react";

const Testimonial = ({ quote, name_class, bg_image, visibility }) => (
    <div className={visibility}>
        <div className="c-testimonial__quote">
            <blockquote>{quote}</blockquote>
            <p>{name_class}</p>
        </div>
        <div className="c-testimonial__figure u-hide u-hide__m">
            <div style={{backgroundImage: `url(${bg_image}`}}>&nbsp;</div>
        </div>
    </div>
);

export default Testimonial;