
const categoryContainer = document.querySelectorAll( '.c-category-browser' );
const NONE_SELECTED_VALUE = 0;

if ( categoryContainer ) {
    const categorySelects = document.querySelectorAll( '.cat-dropdown' );

    /*try {
        const { slug } = settings.queriedObject;
        [...categorySelect.options].forEach( option => {
            if ( option.getAttribute( 'value' ) === slug ) {
                categorySelect.selectedIndex = option.index;
            }
        });
    } catch ( e ) {
        console.log( e.message );
    }*/

    [...categorySelects].map( select => {
        select.addEventListener( 'change', event => {
            let selectElem = event.target;
            let category = selectElem[selectElem.selectedIndex].getAttribute( 'value' );

            if ( category !== NONE_SELECTED_VALUE ) {
                window.location.assign(`/category/${category}`);
            }
        });

        [...select.options].forEach( option => {
            try {
                const { slug } = settings.queriedObject;
                if ( option.getAttribute( 'value' ) === slug ) {
                    select.selectedIndex = option.index;
                }
            } catch ( e ) {
                //console.log( e.message );
            }
        });
    });
}