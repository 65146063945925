import React from "react";
import {render} from "react-dom";

const formatEmail = email => (
    <a href={`mailto:${email}`}>{email}</a>
);

const Directory = ({ row_dept, row_email, row_name, row_office, row_phone, row_title }) => (
    <tr>
        <td className="name">{row_name}</td>
        <td className="phone">{row_phone}</td>
        <td className="office">{row_office}</td>
        <td className="email">{row_email}</td>
    </tr>
);

export default Directory;