let
    mastheadElem = document.getElementById('masthead'),
    audienceNavElem = document.getElementById('audience-navigation'),
    brandingElem = document.querySelector('.js-branding'),
    contentElem = document.getElementById('content'),
    isHomePage = document.body.classList.contains('home'),
    resizeTimeout;

function getBrandingElemOffset() {
    let mastHeight = mastheadElem.offsetHeight;
    let audienceHeight = audienceNavElem.clientHeight;
    return (mastHeight - audienceHeight);
}

function positionContent() {
    let posYShift = getBrandingElemOffset();
    //console.log(posYShift, 'y');
    contentElem.style.top = `-${posYShift}px`;
    contentElem.style.marginBottom = `-${posYShift}px`;
}

function resizeThrottler() {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if ( !resizeTimeout ) {
        resizeTimeout = setTimeout(() => {
            resizeTimeout = null;
            positionContent();
        }, 60);
    }
}

if (isHomePage === true) {
    positionContent();

    window.addEventListener('resize', resizeThrottler, false);
}

