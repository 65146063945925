import request from "../util/xhr";
import JSONParse from "../util/jsonParse";

const containerBlock = document.querySelector('.c-feature-blocks__block--twitter');

let tweetData;

if ( containerBlock ) {

    request(mwccAjaxObj).then(data => {
        let parser = new JSONParse(data);
        parser.parse();
        tweetData = parser.get();

        let tweetHtml = '';

        [...tweetData].forEach(tweetObject => {
            const { id, created_at, text, retweeted } = tweetObject;
            const { name, screen_name, url, profile_image_url_https } = tweetObject.user;
            let mentions = getMentions(tweetObject.entities.user_mentions);

            tweetHtml += `<article class="block-post__twitter">`;
            tweetHtml += `<div class="block-post__twitter--tweet">`;
            tweetHtml += `<div class="block-post__twitter--tweet-image"><img src="${profile_image_url_https}" /></div>`;
            tweetHtml += `<div class="block-post__twitter--tweet-content">`;
            tweetHtml += `<div class="tweet-name">${name}@ ${screen_name}</div>`;
            tweetHtml += `<div class="tweet-time">${relative_time(created_at)}</div>`;
            tweetHtml += `<div class="tweet-text"><p>${addLinks(text)}</p></div>`;
            tweetHtml += `<div class="tweet-mentions">${mentions}</div>`;
            tweetHtml += `</div>`;
            tweetHtml += `</div>`;
            tweetHtml += `</article>`;

        });

        containerBlock.querySelector('.c-feature-blocks__block--body').innerHTML = tweetHtml;

    }).catch(error => {
        console.warn(`Could not authenticate with Twitter API - ${error}`);
    });
}

function getMentions( data ) {
    let mentions = [];

    [...data].forEach( m => {
        mentions.push( `#${m.screen_name}` );
    });

    return mentions.map( m => {
        return addHashTagLinks( m );
    }).join(' ');
}

function addHashTagLinks(data) {
    //Add link to #hastags used within tweets
    return data.replace(/\B#([_a-z0-9]+)/ig, function(reply) {
        return '<a href="https://twitter.com/search?q='+reply.substring(1)+'" target="_blank">'+reply.charAt(0)+reply.substring(1)+'</a>';
    });
}

function addLinks(data) {
    //Add link to all http:// links within tweets
    data = data.replace(/((https?|s?ftp|ssh)\:\/\/[^"\s\<\>]*[^.,;'">\:\s\<\>\)\]\!])/g, function(url) {
        return '<a href="'+url+'"  target="_blank">'+url+'</a>';
    });

    //Add link to @usernames used within tweets
    data = data.replace(/\B@([_a-z0-9]+)/ig, function(reply) {
        return '<a href="http://twitter.com/'+reply.substring(1)+'" target="_blank">'+reply.charAt(0)+reply.substring(1)+'</a>';
    });
    //Add link to #hastags used within tweets
    data = data.replace(/\B#([_a-z0-9]+)/ig, function(reply) {
        return '<a href="https://twitter.com/search?q='+reply.substring(1)+'" target="_blank">'+reply.charAt(0)+reply.substring(1)+'</a>';
    });
    return data;
}

function relative_time(time_value) {
    var values = time_value.split(" ");
    time_value = values[1] + " " + values[2] + ", " + values[5] + " " + values[3];
    var parsed_date = Date.parse(time_value);
    var relative_to = (arguments.length > 1) ? arguments[1] : new Date();
    var delta = parseInt((relative_to.getTime() - parsed_date) / 1000);
    var shortdate = time_value.substr(4,2) + " " + time_value.substr(0,3);
    delta = delta + (relative_to.getTimezoneOffset() * 60);

    if (delta < 60) {
        return '1m';
    } else if(delta < 120) {
        return '1m';
    } else if(delta < (60*60)) {
        return (parseInt(delta / 60)).toString() + 'm';
    } else if(delta < (120*60)) {
        return '1h';
    } else if(delta < (24*60*60)) {
        return (parseInt(delta / 3600)).toString() + 'h';
    } else if(delta < (48*60*60)) {
        //return '1 day';
        return shortdate;
    } else {
        return shortdate;
    }
}