export const tableData = `<table class="mwcctable">
<thead>
<tr>
<td width="24%">Administrative Office</td>
<td style="min-width: 100px;" width="25%">Hours</td>
<td width="32%">Notes</td>
</tr>
</thead>
<tbody>
<tr>
<td>Academic Affairs</td>
<td>Mondays - Thursdays 8AM - 4:30PM
Fridays 8AM - 4PM</td>
<td>Phone: 978-630-9208</td>
</tr>
<tr>
<td>Admissions</td>
<td>Mondays, Wednesdays, Thursdays 8AM - 5PM
Tuesdays 8AM - 7PM
Fridays 8AM - 4PM</td>
<td>Phone: 978-630-9110</td>
</tr>
<tr>
<td>Advising</td>
<td>Mondays, Wednesdays, Thursdays 8AM - 5PM
Tuesdays 8AM - 7PM
Fridays 8AM - 4PM</td>
<td>Phone: 978-630-9109</td>
</tr>
<tr>
<td>Bookstore - Devens Campus</td>
<td>Varies by Month</td>
<td>Check <a href="http://mwcc.edu/devens/" target="_blank">Devens webpage</a> for details.</td>
</tr>
<tr>
<td>Bookstore - Gardner Campus</td>
<td>Mondays - Thursdays 8:30AM - 4PM
Fridays 8:30AM - 3PM</td>
<td>Phone: 978-630-8238<br>Extended hours are available during the first weeks of a semester.</td>
</tr>
<tr>
<td>Bookstore - Leominster Campus</td>
<td>Varies by Month</td>
<td>Check <a href="http://mwcc.edu/leominster/" target="_blank">Leominster webpage</a> for details.</td>
</tr>
<tr>
<td>Cafeteria</td>
<td>Mondays - Thursdays 7:30AM - 2PM &amp; 4:30PM - 7:30PM
Fridays 7:30AM - 1:30PM</td>
<td></td>
</tr>
<tr>
<td>Devens Campus</td>
<td>Mondays - Thursdays 8AM - 6:30PM
Fridays 8AM - 4PM</td>
<td>Phone: 978-630-9569<br>The Devens Campus is open on Saturdays from 8AM - 2PM.</td>
</tr>
<tr>
<td>Financial Aid</td>
<td>Mondays, Wednesdays, Thursdays 8AM - 5PM
Tuesdays 8AM - 7PM
Fridays 8AM - 4pm</td>
<td>Phone: 978-630-9169</td>
</tr>
<tr>
<td>Human Resources</td>
<td>Mondays - Thursdays 8AM - 5PM
Fridays 8AM - 4PM</td>
<td>Phone: 978-630-9108</td>
</tr>
<tr>
<td>Leominster Campus</td>
<td>Mondays - Thursdays 8AM - 7PM
Fridays 8AM - 4PM</td>
<td>The Leominster Campus is open as needed on Friday evenings and Saturdays.</td>
</tr>
<tr>
<td>Library</td>
<td>Mondays - Thursdays 7:30AM - 7:30PM
Fridays 7:30AM - 4PM</td>
<td>Phone: 978-630-9125<br>
Library hours change during the summer and academic calendar intersessions.</td>
</tr>
<tr>
<td>Library- Leominster Campus</td>
<td>Wednesdays &amp; Thursdays 8AM - 9AM &amp; 4PM - 5PM</td>
<td>The Leominster Campus Library is also open by appointment. Please call 978-630-9338.</td>
</tr>
<tr>
<td>Lifelong Learning (Noncredit Courses)</td>
<td>Mondays - Thursdays 8AM - 5PM
Fridays 8AM - 4PM</td>
<td>Phone: 978-630-9124</td>
</tr>
<tr>
<td>Records Office</td>
<td>Mondays - Fridays 8AM - 4PM</td>
<td>Phone: 978-630-9106</td>
</tr>
<tr>
<td>Student Accounts</td>
<td>Mondays - Thursdays 8AM - 5PM
Fridays 8AM - 4PM</td>
<td>Phone: 978-630-9149</td>
</tr>
<tr>
<td>Testing Center</td>
<td>Mondays, Wednesdays, Thursdays, Fridays  8AM - 4PM
Tuesdays 8AM - 8PM</td>
<td>Phone: 978-630-9244</td>
</tr>
<tr>
<td>Tutoring Center</td>
<td>Monday—Friday<br>
(times vary based on semester)</td>
<td>Phone: 978-630-9333<br>
Go to <a href="http://mwcc.edu/lib/asc" target="_blank">mwcc.edu/lib/asc</a> for current details.</td>
</tr>
<tr>
<td>Tutoring Center - Leominster Campus</td>
<td>Monday—Friday<br>
(times vary based on semester)</td>
<td>Go to <a href="http://mwcc.edu/lib/asc" target="_blank">mwcc.edu/lib/asc</a> for current details.</td>
</tr>
</tbody>
</table>`;