
let menuTitleFormatter = title => {
    let formattedTitle = '';

    title = title.toLowerCase();
    title = title.replace(/\W/g, ''); // strip out any non-words like spaces, & ,
    //let iterator = title[Symbol.iterator]();
    //console.log(iterator, 'iterator');

    if (title.indexOf(' ') !== -1) {
        title = title.split(' ');
        if (title.length > 2) {
            title.splice(1, 1);
        }
        formattedTitle = title.join('');
    } else {
        formattedTitle = title;
    }

    return formattedTitle;
};

export default menuTitleFormatter;