import React from "react";
import PropTypes from "prop-types";
import Lightbox from "react-images";

const GalleryItems = ({id, alt, sizes, title, url, width, height}) => {
    const {thumbnail} = sizes;

    return (
        <div className="item">
            <img alt={alt} title={title} src={thumbnail} />
        </div>
    )
};


GalleryItems.propTypes = {
    id: PropTypes.number,
    alt: PropTypes.string,
    sizes: PropTypes.object,
    url: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
};

export default GalleryItems;