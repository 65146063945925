import React from "react";
import ReactDOM, {render} from "react-dom";
import PropTypes from "prop-types";

class DirectoryInput extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind( this );
    }

    handleChange() {
        this.props.onUserInput( this.refs.filterTextInput.value );
    }

    render() {

        return (
            <div className="data-table-filter l-form l-form__field ">
                <p>
                    <label>Search by name: </label><br />
                    <input className="l-form__field--input text-field" type="text" placeholder="Search" ref="filterTextInput" value={this.props.filterStr} onChange={this.handleChange} />
                </p>
            </div>
        )
    }
}

DirectoryInput.propTypes = {
    filterStr: PropTypes.string,
    onUserInput: PropTypes.func
};


export default DirectoryInput;