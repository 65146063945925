import React from "react";
import ReactDOM, {render} from "react-dom";
import PropTypes from "prop-types";

export default class TabsContainer extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            activeTabIndex: 0
        };

        this.handleTabClick = this.handleTabClick.bind(this);
    }

    handleTabClick( tabIndex ) {
        this.setState({
            activeTabIndex: this.state.activeTabIndex ? this.props.defaultActiveTabIndex : tabIndex
        });
    }

    renderChildrenWithTabsAsProps() {
        //return React.children.map( this.props.children, ( child, index ) => {
        return this.props.children.map( ( child, index ) => {
            return React.cloneElement( child, {
                onClick: this.handleTabClick,
                key: index,
                tabIndex: index,
                isActive: index === this.state.activeTabIndex
            });
        });
    }

    componentDidMount() {
        //console.log(this.props, 'mount');
        /*React.children.map( this.props.children, (child, index) => {
           console.log(child, 'child');
        });*/
    }

    renderActiveTabContent() {
        const {children} = this.props;
        const {activeTabIndex} = this.state;
        if ( children[activeTabIndex] ) {
            return children[activeTabIndex].props.children;
        }
    }

    render() {
        return (
            <div className="c-tabs">
                <ul className="tabs">
                    {this.renderChildrenWithTabsAsProps()}
                </ul>
                <div className="tabs-active-content">
                    {this.renderActiveTabContent()}
                </div>
            </div>
        )
    }
}

TabsContainer.propTypes = {
    defaultActiveTabIndex: PropTypes.number
};

TabsContainer.defaultProps = {
    defaultActiveTabIndex: 0
};