/*global mwccRestApi, globalPost */
import axios from "axios";
import functionTest from "../util/functionTest";

class RestData {
    constructor( postType = 'news-article', ...params ) {
        const appUrl = mwccRestApi.home;
        const restApiUrl = mwccRestApi.rest;
        const testimonialId = mwccRestApi.home_testimonial;
        const { postID } = globalPost;
        const formAssembly = 'https://mwcc.tfaforms.net/217719';
        const catalogUrl = 'http://catalog.mwcc.edu/';

        this.axiosInstance = {};

        this.formEndpoint = formAssembly;
        //this.directoryEndpoint = `${appUrl}/wp-content/themes/mwcc/assets/directory/Directory.xml`;
        this.directoryEndpoint = 'https://calendar.mwcc.edu/mobile-app/directory.xml';
        this.postsEndPoint = `${appUrl}/wp-json/wp/v2/${postType}`; // Endpoint for WP posts
        this.postIdEndPoint = `${appUrl}/wp-json/wp/v2/${postType}/${postID}`;
        this.catEndPoint = `${appUrl}/wp-json/wp/v2/categories?per_page=50&hide_empty=1`;
        this.testimonialsEndPoint = `${restApiUrl}selectedTestimonial/${testimonialId}`; // Endpoint for selected testimonial
    }

    set postType( postType ) {
        this._postType = postType;
    }

    api( endPoint ) {
        return new Promise( ( resolve, reject ) => {
            axios.get( endPoint ).then( ( response ) => {
                resolve( response.data );
            }).catch( ( error ) => {
                reject( error );
            });
        });
    }


    apiInstance( endPoint ) {
        this.axiosInstance = axios.create({
            responseType: 'document'
        });

        return new Promise( (resolve, reject) => {
            this.axiosInstance.get(endPoint).then(response => {
                resolve(response.data);
            }).catch( ( error ) => {
                reject( error );
            });
        })
    }

    getCategories( cb ) {
        this.api( this.catEndPoint).then( response => {
            const payload = response;
            if ( functionTest( cb ) ) {
                cb( payload );
            } else {
                return payload;
            }
        }).catch( error => {
            console.warn( error );
        });
    }

    getCurrentPost( cb ) {
        this.api( this.postIdEndPoint ).then( response => {
            const payload = response;
            if ( functionTest( cb ) ) {
                cb( payload );
            } else {
                return payload;
            }
        }).catch( error => {
            console.warn( error );
        });
    }

    getDirectory( cb ) {
        this.apiInstance( this.directoryEndpoint).then( response => {
            const payload = response;
            if ( functionTest( cb ) ) {
                cb( payload );
            } else {
                return payload;
            }
        }).catch( error => {
            console.warn( error );
        });
    }

    getForm( cb ) {
        this.apiInstance( this.formEndpoint).then( response => {
            console.log(response, 'form response');
        }).catch( error => {
            console.warn( error, 'form error');
        });
    }

    getPosts( cb ) {
        this.api(this.postsEndPoint).then( response => {
            const payload = response;
            if ( functionTest( cb ) ) {
                cb( payload );
            }
        }).catch( error => {
            console.warn( error );
        });
    }

    getTestimonial( cb ) {
        this.api( this.testimonialsEndPoint ).then( response => {
            const payload = response;
            if ( functionTest( cb ) ) {
                cb( payload );
            }
        }).catch( error => {
            console.warn( error );
        });
    }
}

export default RestData;