import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import RestData from "./RestData";
import GalleryItems from "./gallery-items";
import GalleryGrid from "./gallery-grid";
import Lightbox from "react-image-lightbox";
//import Loader from "pure-css-loader";

class GalleryContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            galleryItems: [],
            imageSet: [],
            lightboxSet: [],
            isLoading: true
        };
        this.imageWidth = 150;
        this.imageHeight = 150;
    }

    componentDidMount() {
        let postType;
        try {
            postType = settings.queriedObject.post_type;
            if ( postType === 'page' ) {
                postType = `${postType}s`;
            }
        } catch ( e ) {
            console.warn(e.message);
        }

        let rest = new RestData( postType );
        rest.getCurrentPost( payload => {
            const {page_gallery} = payload.acf;
            this.addGalleryItems( page_gallery );
        });
    }

    addGalleryItems( items ) {
        let imageSet = items.map( item => {
            return Object.assign( {}, {
                //src: item.url,
                src: item.sizes.thumbnail,
                width: 150,
                height: 150,
                alt: item.alt
            });
        });
        let lightboxSet = items.map( item => {
            return Object.assign( {}, {
                thumbnail: item.sizes.thumbnail,
                src: item.url,
                description: item.description,
                caption: item.caption
            });
        });
        this.setState({
            galleryItems: items,
            imageSet: imageSet,
            lightboxSet: lightboxSet,
            isLoading: false
        });
    }

    render() {
        const {isLoading} = this.state;
        let isActive = isLoading ? 'is-active' : '';

        return (
            <div>
                <div className={`loader loader-default ${isActive}`}></div>
                <GalleryGrid
                    collection={this.state.imageSet}
                    lightboxColl={this.state.lightboxSet}
                />
            </div>
        );
        /*return (
            <div>
                {this.state.galleryItems.map( item => (
                    <GalleryItems
                        key={item.id}
                        id={item.id}
                        alt={item.alt}
                        sizes={item.sizes}
                        title={item.title}
                        url={item.url}
                        width={item.width}
                        height={item.height}/>
                ))};
            </div>
        )*/
    }
}

if ( document.getElementById( 'galleryMountPoint' ) ) {
    ReactDOM.render( <GalleryContainer />, document.getElementById( 'galleryMountPoint' ) );
}