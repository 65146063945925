import multiListener from "../util/multiListener";
import menuTitleFormatter from "../util/menuTitleFormatter";

const TOGGLE_CLASS = 'toggled';
const ACTIVE_CLASS = 'is-active';
const VISIBLE_CLASS = 'is-visible';
const MEGA_CLASS_SELECTOR = 'o-mega-widget-';

let topNavigation = document.getElementById('top-navigation');
let topMenuElems = document.querySelectorAll('.l-menu-top > ul > li');
//let mobileMenuElems = document.querySelectorAll('.l-menu-mobile > ul > li > a');
let mobileMenuElems = document.querySelectorAll('.l-menu-mobile > ul > li a');
let menuTrigger = document.querySelector('.js-mobile-trigger');
let menu = document.getElementById('mobile-navigation');

let topMenuHandler = (event, handler) => {

    if (!event instanceof Event) return false;

    let target = event.target;

    // IE no comprendo .item()
    //let targetChild = target.children.item('a');
    let targetChild = target.childNodes[0];

    try {
        let targetValue = targetChild.innerText;
        let megaElem;

        targetValue = menuTitleFormatter(targetValue);

        megaElem = target.querySelector(`.${MEGA_CLASS_SELECTOR}${targetValue}`);
        megaElem.classList.contains(VISIBLE_CLASS)
            ? megaElem.classList.remove(VISIBLE_CLASS)
            : megaElem.classList.add(VISIBLE_CLASS);
    } catch (exception) {
        console.log(exception.message);
    }

    switch (event.type) {
        case 'mouseenter':
            target.classList.add('hover');
            break;
        case 'mouseleave':
            target.classList.remove('hover');
            break;
    }
};

let mobileMenuHandler = () => {

    let menuIsActive = menu.classList.contains(ACTIVE_CLASS);

    if ( !menuIsActive ) {
        menu.setAttribute('aria-expanded', true);
        menu.classList.add(ACTIVE_CLASS);
        menuTrigger.setAttribute('aria-expanded', true);
        menuTrigger.classList.add(ACTIVE_CLASS);
    } else {
        menu.setAttribute('aria-expanded', false);
        menu.classList.remove(ACTIVE_CLASS);
        menuTrigger.setAttribute('aria-expanded', false);
        menuTrigger.classList.remove(ACTIVE_CLASS);
    }
};

multiListener(topMenuElems, 'mouseenter mouseleave', event => topMenuHandler(event, topMenuElems));
try {
    menuTrigger.addEventListener('click', mobileMenuHandler);
} catch ( e ) {
    console.warn(`Navigation elements not available to attach listener to - ${e.message}`);
}


[...mobileMenuElems].forEach(link => {
    link.addEventListener('click', event => {

        let target = event.target;
        let isToggled = link.classList.contains(TOGGLE_CLASS);

        if (target.nodeName === 'SPAN') {
            event.preventDefault();
            if ( !isToggled ) {
                link.classList.add(TOGGLE_CLASS);
                link.nextElementSibling.classList.add(TOGGLE_CLASS);
            } else {
                link.classList.remove(TOGGLE_CLASS);
                link.nextElementSibling.classList.remove(TOGGLE_CLASS);
            }
        }
    });
});