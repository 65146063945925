
const iframeElems = document.getElementsByTagName('iframe');
const players = /www.youtube.com|player.vimeo.com/;
const WRAPPER_CLASS = 'videoWrapper';
let videoRatio;

function playerTest(iframe) {
    return ( iframe.src.search(players) !== -1 );
}

/*
 * Replace the iframe's dimensions and position
 * the iframe absolute, this is the trick to emulate
 * the video ratio
 */
function applyAttributes(iframe) {
    iframe.style.position = 'absolute';
    iframe.style.top = '0';
    iframe.style.left = '0';
    iframe.width = '100%';
    iframe.height = '100%';
}

let fluidVids = () => {

    [...iframeElems].forEach( frame => {
        if ( frame && playerTest( frame ) ) {
            videoRatio = ( frame.height / frame.width ) * 100;
            let videoWrapper = frame.parentNode;
            if ( videoWrapper && videoWrapper.classList.contains( WRAPPER_CLASS ) ) {
                videoWrapper.style.paddingTop = `${videoRatio}%`;
            }
            //applyAttributes( frame );
        }
    })
};

export default fluidVids;