import React from "react";
import {render} from "react-dom";
import PropTypes from "prop-types";

class Tab extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.handleTabClick = this.handleTabClick.bind(this);
    }

    handleTabClick(event) {
        event.preventDefault();
        this.props.onClick(this.props.tabIndex);
    }

    render() {
        return (
            <li className="tab">
                <a className={`tab-link ${this.props.linkClassName} ${this.props.isActive ? 'active' : ''}`}
                   onClick={this.handleTabClick}>{this.props.tabTitle}
                    <i className={`tab-icon ${this.props.iconClassName}`}/>
                </a>
            </li>
        )
    }
}

/*const Tab = ( props ) => {
  return (
      <li className="tab">
          <a className={`tab-link ${props.linkClassName} ${props.isActive ? 'active' : ''}`}
             onClick={(event) => {
                event.preventDefault();
                props.onClick( props.tabIndex );
             }}>
              <i className={`tab-icon ${props.iconClassName}`}/>
          </a>
      </li>
  )
};*/

Tab.propTypes = {
    onClick: PropTypes.func,
    tabIndex: PropTypes.number,
    isActive: PropTypes.bool,
    tabTitle: PropTypes.string.isRequired,
    iconClassName: PropTypes.string.isRequired,
    linkClassName: PropTypes.string.isRequired
};

export default Tab;

