/**
 * Event util - listens for multiple event types, e.g. mouseenter mouseleave
 * and attaches a specified event listener
 *
 * @param el
 * @param listeners
 * @param fn
 */
let multiListener = (el, listeners, fn) => {
    if (el instanceof NodeList) {
        [...el].forEach(element => {
            listeners.split(" ").forEach(l => element.addEventListener(l, fn, false));
        });
    } else {
        listeners.split(" ").forEach(l => el.addEventListener(l, fn, false));
    }
};

export default multiListener;