import React from "react";
import ReactDOM, {render} from "react-dom";
import PropTypes from "prop-types";
import Directory from "./directory";
import TableHeaders from "./table-headers";
import DirectoryOffice from "./directory-office";
import DirectoryInput from "./directory-input";
import DirectoryData from "./directory-data";
import TabsContainer from "./tabs-container";
import Tab from "./tabs";
import {tableData} from "./directory-table-data";
//import List from "list.js";
//import $ from "jquery";

//import {debounce} from "throttle-debounce";
import RestData from "./RestData";

class DirectoryContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            directoryData: [],
            directoryHeaders: [
                {
                    label: 'Name',
                    notation: '',
                    sortBy: 'name'
                },
                {
                    label: 'Phone',
                    notation: '',
                    sortBy: 'phone'
                },
                {
                    label: 'Office',
                    notation: '',
                    sortBy: 'office'
                },
                {
                    label: 'Email',
                    notation: '(@mwcc.mass.edu)',
                    sortBy: 'email'
                }
            ],
            hoursData: [],
            filterStr: ""
        };

        this.handleUserInput = this.handleUserInput.bind( this );
        this.handleTableData = this.handleTableData.bind( this );
        this.handleSort = this.handleSort.bind( this );
    }

    createMarkup( html ) {
        return {
            __html: html
        };
    }

    getMarkup() {
        return {
            __html: this.state.hoursData
        };
    }

    handleUserInput( filterStr ) {
        this.setState({
            filterStr: filterStr.toLowerCase()
        });
    }

    handleTableData( event ) {
        var item = {
            id: event.target.id,
            name: event.target.name,
            value: event.target.value
        };
        var products = this.state.directoryData.slice();
        var newProducts = products.map(function(product) {

            for (var key in product) {
                if (key == item.name && product.id == item.id) {
                    product[key] = item.value;

                }
            }
            return product;
        });

        this.setState({directoryData: newProducts});
    }

    componentDidMount() {
        let model = new RestData( 'pages' );
        model.getDirectory( data => {
            const formattedRows = this.handleModel( data );
            this.setState({
                directoryData: formattedRows
            });
        });
        model.getCurrentPost( data => {
            const { administrative_office_hours: hours } = data.acf;
            this.createMarkup( hours );
            this.setState({
                hoursData: hours
            });
        });
    }

    formatEmail( email ) {
        try {
            let atPos = email.indexOf( '@' );
            if ( atPos === -1 ) {
                return email;
            }
            return email.substring( 0, atPos );
        } catch (exception) {
            console.warn(exception);
        }
    }

    formatRow( row, nodeName ) {
        console.log(row, 'row');
        //let element = row.querySelector( nodeName );
        let element = row.find( nodeName );

        if ( element instanceof Element ) {
            if ( nodeName === 'EMAIL' ) {
                //return this.formatEmail( element.innerHTML );
                return this.formatEmail( element.textContent );
            }
            //return element.innerHTML;
            return element.textContent;
        } else {
            return "";
        }
    }

    handleModel( xmlDocumentData ) {
        let jqRow = $(xmlDocumentData).find('ROW');

        //let xmlDocRows = xmlDocumentData.querySelectorAll('ROW');
        let rowData = [{}];

        //[...jqRow].forEach( row => {
        jqRow.each(function() {
            //let row = $(this);
            //console.log(row.innerHTML, 'row');
            let row_name = $(this).find('NAME').text();
            let row_dept = $(this).find('DEPARTMENT').text();
            let row_title = $(this).find('TITLE').text();
            let row_phone = $(this).find('PHONE').text();
            let row_office = $(this).find('OFFICE').text();
            let row_email = $(this).find('EMAIL').text();

            let atPos = row_email.indexOf( '@' );
            if ( atPos !== -1 ) {
                row_email = row_email.substring( 0, atPos );
            }
            /*let row_name = this.formatRow( row, 'NAME' );
            let row_dept = this.formatRow( row, 'DEPARTMENT' );
            let row_title = this.formatRow( row, 'TITLE' );
            let row_phone = this.formatRow( row, 'PHONE' );
            let row_office = this.formatRow( row, 'OFFICE' );
            let row_email = this.formatRow( row, 'EMAIL' );*/
            rowData.push({
                row_name: row_name,
                row_dept: row_dept,
                row_title: row_title,
                row_phone: row_phone,
                row_office: row_office,
                row_email: row_email
            });
        });
        /*[...xmlDocRows].forEach( row => {
            //console.log(row.innerHTML, 'row');
            let row_name = this.formatRow( row, 'NAME' );
            let row_dept = this.formatRow( row, 'DEPARTMENT' );
            let row_title = this.formatRow( row, 'TITLE' );
            let row_phone = this.formatRow( row, 'PHONE' );
            let row_office = this.formatRow( row, 'OFFICE' );
            let row_email = this.formatRow( row, 'EMAIL' );
            rowData.push({
                row_name: row_name,
                row_dept: row_dept,
                row_title: row_title,
                row_phone: row_phone,
                row_office: row_office,
                row_email: row_email
            });
        });*/
        return rowData;
    }

    handleSort( ...immutables ) {
        console.log(immutables, 'args handle sort');
        const sortedData = immutables[1].sortBy(item => {
           return item.get(immutables[0].get('sortBy'));
        });
        this.setState({
            directoryData: sortedData
        });
        //const sortedData = data.sortBy((item) => item.get(cell.get('sortBy')));
        //this.setState({directoryData: sortedData});
    }

    render() {

        return (
            <div className="c-faculty-staff">
                <TabsContainer>
                    <Tab iconClassName={'first'} linkClassName={'firstclass'} tabTitle="Administrative Office Hours">
                        <div dangerouslySetInnerHTML={this.getMarkup()}></div>
                    </Tab>
                    <Tab iconClassName={'icon-class-0'} linkClassName={'link-class-0'} tabTitle="Faculty and Staff Listing">

                        <DirectoryInput filterStr={this.state.filterStr} onUserInput={this.handleUserInput} />

                        <table id="mwcc-directory-table" className="mwcc-table">
                            <thead>
                                <TableHeaders headers={this.state.directoryHeaders} directoryData={this.state.directoryData} onUpdate={this.handleSort} />
                            </thead>

                            <DirectoryData filterStr={this.state.filterStr} data={this.state.directoryData} />

                        </table>
                    </Tab>
                </TabsContainer>
            </div>
        );
    }
}

const currPage = document.body.classList.contains('page-template-template-page-offices');
if ( currPage ) {
    ReactDOM.render( <DirectoryContainer />, document.querySelector('.entry-content') );
}

/*
 {this.state.directoryData.map( (row, i) =>
 <Directory key={i} {...row} />
 )}
 */